.read-more-link {
    font-size: 0.8rem;
    color: #B68D40;
    transition-property: font-size;
        transition-duration: 1s;
}
   
.read-more-link:hover {
        font-size: 1.2rem;
}

a {
    text-decoration: none;
    color: #B68D40;
}
@media screen and (min-width: 200px) and (max-width: 768px) {
.aboutMe {
    margin: 40px 20px 0px 20px;
  }
}